import React from "react";
import PropTypes from "prop-types";

const HomeBody = props => {
  const { children, theme } = props;

  return (
    <React.Fragment>
      <div className="container">
        <div className="matrix-bg"></div>
        <article className="article">{children}</article>
      </div>

      {/* --- STYLES --- */}
      <style jsx>{`
        .container {
          display: flex;
          flex: 1;
          position: relative;
          overflow: hidden;
          min-height: 80vh;
          background: var(--background);
        }

        .matrix-bg {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          opacity: 0.05;
          background: 
            linear-gradient(to right, var(--text) 1px, transparent 1px) 0 0,
            linear-gradient(to bottom, var(--text) 1px, transparent 1px) 0 0;
          background-size: 20px 20px;
          animation: slide 20s linear infinite;
        }

        @keyframes slide {
          0% {
            transform: translateY(0);
          }
          100% {
            transform: translateY(20px);
          }
        }

        .article {
          padding: ${theme.space.inset.default};
          margin: 0 auto;
          display: flex;
          flex: 1;
          width: 100%;
          max-width: 1200px;
          justify-content: center;
          align-items: center;
          position: relative;
          z-index: 0;
        }

        @media (max-width: 768px) {
          .article {
            padding: ${theme.space.inset.default};
          }
        }
      `}</style>
    </React.Fragment>
  );
};

HomeBody.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.object.isRequired
};

export default HomeBody;
