import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import theme from "../theme/theme.yaml";
import HomeBody from "../components/Home/HomeBody";
import Seo from "../components/Seo";

const HomePage = props => {
  const [text, setText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [loopNum, setLoopNum] = useState(0);
  const [typingSpeed, setTypingSpeed] = useState(150);

  const words = ['incríveis', 'escaláveis', 'inovadores', 'intuitivos', 'performáticos'];
  
  useEffect(() => {
    let timer;
    const handleType = () => {
      const current = loopNum % words.length;
      const fullText = words[current];

      setText(isDeleting 
        ? fullText.substring(0, text.length - 1)
        : fullText.substring(0, text.length + 1)
      );

      setTypingSpeed(isDeleting ? 100 : 150);

      if (!isDeleting && text === fullText) {
        setTimeout(() => setIsDeleting(true), 1500);
      } else if (isDeleting && text === '') {
        setIsDeleting(false);
        setLoopNum(loopNum + 1);
      }
    };

    timer = setTimeout(handleType, typingSpeed);
    return () => clearTimeout(timer);
  }, [text, isDeleting, loopNum, typingSpeed]);

  return (
    <React.Fragment>
      <HomeBody theme={theme}>
        <div className="content" onClick={(e) => e.stopPropagation()}>
          <div className="intro">
            <div className="greeting">
              <span className="tag">{"<hello />"}</span>
              <h4>Olá, meu nome é</h4>
            </div>
            
            <h1>Décio Montanhani</h1>
            
            <div className="description">
              <span className="tag">{"<description />"}</span>
              <p>
                Desenvolvedor <strong>iOS</strong> apaixonado por criar apps <strong className="typewriter">{text}</strong>
              </p>
            </div>

            <div className="actions">
              <Link to="/blog" className="button gradient-button" role="button">
                <span>Conheça o meu blog</span>
              </Link>
            </div>
          </div>
        </div>
      </HomeBody>
      <Seo pageTitle="Home" />

      <style jsx>{`
        .content {
          text-align: left;
          max-width: 800px;
          padding: 0 20px;
          cursor: default;
        }

        .intro {
          animation: fadeIn 1s ease-out;
        }

        .greeting {
          margin-bottom: 1rem;
          display: flex;
          align-items: center;
          gap: 1rem;
        }

        .tag {
          color: var(--primary);
          font-family: 'SF Mono', 'Courier New', monospace;
          opacity: 0.8;
          font-size: 1rem;
          padding: 0.2rem 0.5rem;
          background: rgba(255, 55, 95, 0.1);
          border-radius: 4px;
          border: 1px solid var(--primary);
        }

        h1 {
          font-size: 4.5rem;
          margin: 1rem 0;
          background: linear-gradient(120deg, var(--primary), #AC8E68);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          animation: gradientFlow 8s ease infinite;
          font-weight: 700;
        }

        h4 {
          font-size: 1.5rem;
          color: var(--text);
          margin: 0.5rem 0;
          font-weight: 500;
        }

        .description {
          margin: 2rem 0;
        }

        p {
          font-size: 1.8rem;
          color: var(--text);
          margin: 0.5rem 0;
          line-height: 1.6;
          font-weight: 300;
          max-width: 600px;
          white-space: pre-line;
        }

        strong {
          color: var(--primary);
          font-weight: 600;
        }

        .typewriter {
          border-right: 2px solid var(--primary);
          padding-right: 5px;
          animation: blink 0.7s step-end infinite;
          display: inline-block;
        }

        @keyframes blink {
          from,
          to {
            border-color: transparent;
          }
          50% {
            border-color: var(--primary);
          }
        }

        .actions {
          margin-top: 3rem;
          display: flex;
          flex-direction: column;
          gap: 2rem;
        }
        
        /** Adicionando alguns estilos específicos para o botão da Home **/
        :global(.actions .button) {
          width: fit-content;
        }

        @keyframes fadeIn {
          from {
            opacity: 0;
            transform: translateY(20px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }

        @keyframes gradientFlow {
          0% {
            background-position: 0% 50%;
          }
          50% {
            background-position: 100% 50%;
          }
          100% {
            background-position: 0% 50%;
          }
        }

        @media (max-width: 768px) {
          h1 {
            font-size: 3rem;
          }

          p {
            font-size: 1.4rem;
          }
          
          .greeting {
            flex-direction: column;
            align-items: flex-start;
            gap: 0.5rem;
          }
        }
      `}</style>
    </React.Fragment>
  );
};

export default HomePage;
